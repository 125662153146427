// pull in application variables via callback
@import 'app/_variables.scss';

// form input tweaks
input[type='radio'].form-control-sm {
  width: inherit;
}
input[type='radio'].form-control-lg {
  width: inherit;
}
